import React from "react";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";

const geoFileJSON = require("./gadm41_MYS_1.json");

const markers = [
  {
    markerOffsetY: 10,
    name: "National Cancer Institute",
    coordinates: [101.67449218376359, 2.927089145064261],
  },
  {
    markerOffsetY: -30,
    name: "Kuala Lumpur Hospital",
    coordinates: [101.70258501600742, 3.171793191586478],
  },
  {
    markerOffsetY: -30,
    name: "Penang General Hospital",
    coordinates: [100.31129791393406, 5.4170990461516855],
  },
  {
    markerOffsetY: -28,
    name: "Sultanah Aminah Hospital",
    coordinates: [103.74679661804223, 1.46023636096772],
  },
  {
    markerOffsetY: -30,
    name: "Sarawak General Hospital",
    coordinates: [110.33963500002088, 1.5438263211824326],
  },
  {
    markerOffsetY: -30,
    name: "Sabah Women & Children Hospital",
    coordinates: [116.1194192191941, 6.015397143488891],
  },
  {
    markerOffsetX: 66,
    markerOffsetY: -10,
    name: "Actively using RadDB",
    coordinates: [107.1, 6],
  },
  {
    markerOffsetX: 45.5,
    markerOffsetY: -10,
    name: "Beta testing",
    coordinates: [107.1, 5.015397143488891],
  },
];

const MapChart = () => (
  <ComposableMap
    projection="geoAzimuthalEqualArea"
    projectionConfig={{
      rotate: [251.3, -4, 0],
      scale: 2000,
    }}
    width={900}
    height={300}
  >
    <Geographies geography={geoFileJSON}>
      {({ geographies }) =>
        geographies.map((geo) => (
          <Geography key={geo.rsmKey} geography={geo} fill="#EAEAEC" stroke="#D6D6DA" />
        ))
      }
    </Geographies>
    {markers.map(({ name, coordinates, markerOffsetY, markerOffsetX }) => (
      <Marker key={name} coordinates={coordinates}>
        <g
          fill="none"
          stroke={
            name === "Kuala Lumpur Hospital" || name === "Actively using RadDB"
              ? "#09822D"
              : "#5e23b0"
          }
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(-12, -24)"
        >
          <circle cx="12" cy="10" r="3" />
          <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
        </g>
        <text
          textAnchor="middle"
          y={markerOffsetY}
          x={markerOffsetX}
          style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
          fontSize={name === "Actively using RadDB" || name === "Beta testing" ? 9 : 10}
        >
          {name}
        </text>
      </Marker>
    ))}
  </ComposableMap>
);

export default MapChart;
